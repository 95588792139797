import { cloneElement } from 'react';
import { ReactComponent as AddInteraction } from 'assets/icons/addInteraction.svg';
import { ReactComponent as AddProfile } from 'assets/icons/addProfile.svg';
import { ReactComponent as AddToCollection } from 'assets/icons/addToCollection.svg';
import { ReactComponent as AlertOff } from 'assets/icons/alertOff.svg';
import { ReactComponent as AlertOn } from 'assets/icons/alertOn.svg';
import { ReactComponent as Alignment } from 'assets/icons/alignment.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/icons/analytics.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrowDown.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrowRight.svg';
import { ReactComponent as ArrowStraight } from 'assets/icons/arrowStraight.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrowUp.svg';
import { ReactComponent as BookmarkOff } from 'assets/icons/bookmarkOff.svg';
import { ReactComponent as BookmarkOn } from 'assets/icons/bookmarkOn.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as CalendarInfo } from 'assets/icons/calendar-info.svg';
import { ReactComponent as CalendarWarning } from 'assets/icons/calendarWarning.svg';
import { ReactComponent as TickBox } from 'assets/icons/check.svg';
import { ReactComponent as CheckBadge } from 'assets/icons/checkBadge.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checkCircle.svg';
import { ReactComponent as CheckIconGreen } from 'assets/icons/checkCircleGreen.svg';
import { ReactComponent as CheckIconYellow } from 'assets/icons/checkCircleYellow.svg';
import { ReactComponent as CheckGreenCircle } from 'assets/icons/checkGreenCircle.svg';
import { ReactComponent as ClearLeft } from 'assets/icons/clearLeft.svg';
import { ReactComponent as PaperWithClip } from 'assets/icons/clipPaper.svg';
import { ReactComponent as ClockCircle } from 'assets/icons/clockCircle.svg';
import { ReactComponent as Cog } from 'assets/icons/cog.svg';
import { ReactComponent as CommentGroup } from 'assets/icons/commentGroup.svg';
import { ReactComponent as Conferences } from 'assets/icons/conferences.svg';
import { ReactComponent as Connections } from 'assets/icons/connections.svg';
import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { ReactComponent as CreateCollection } from 'assets/icons/createCollection.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as CrossCircle } from 'assets/icons/crossCircle.svg';
import { ReactComponent as CustomReportsModule } from 'assets/icons/customReportsModuleIcon.svg';
import { ReactComponent as Detail } from 'assets/icons/detail.svg';
import { ReactComponent as DoubleArrowLeft } from 'assets/icons/doubleArrowLeft.svg';
import { ReactComponent as DoubleArrowRight } from 'assets/icons/doubleArrowRight.svg';
import { ReactComponent as DownChevron } from 'assets/icons/downChevron.svg';
import { ReactComponent as DownloadArrow } from 'assets/icons/downloadArrow.svg';
import { ReactComponent as EmptyCheckBox } from 'assets/icons/emptyCheckBox.svg';
import { ReactComponent as EmptySelector } from 'assets/icons/emptySelector.svg';
import { ReactComponent as Envelope } from 'assets/icons/envelope.svg';
import { ReactComponent as Eraser } from 'assets/icons/eraser.svg';
import { ReactComponent as Events } from 'assets/icons/events.svg';
import { ReactComponent as Exclamation } from 'assets/icons/exclamationCircle.svg';
import { ReactComponent as ExportToCsvIcon } from 'assets/icons/exportToCsv.svg';
import { ReactComponent as FeedIcon } from 'assets/icons/feed.svg';
import { ReactComponent as FloppyDisk } from 'assets/icons/floppyDisk.svg';
import { ReactComponent as Freeform } from 'assets/icons/freeform.svg';
import { ReactComponent as Fullscreen } from 'assets/icons/fullscreen.svg';
import { ReactComponent as FunnelIcon } from 'assets/icons/funnel.svg';
import { ReactComponent as Geography } from 'assets/icons/geography.svg';
import { ReactComponent as GlobalKOLPlanningModule } from 'assets/icons/globalKolPlanningModuleIcon.svg';
import { ReactComponent as GlobeIcon } from 'assets/icons/globe.svg';
import { ReactComponent as GoBack } from 'assets/icons/goBack.svg';
import { ReactComponent as GraphStatsAscendIcon } from 'assets/icons/graphStatsAscend.svg';
import { ReactComponent as GroupCircle } from 'assets/icons/groupCircle.svg';
import { ReactComponent as Hamburger } from 'assets/icons/hamburger.svg';
import { ReactComponent as HandPointer } from 'assets/icons/handPointer.svg';
import { ReactComponent as Headphones } from 'assets/icons/headPhones.svg';
import { ReactComponent as HospitalIcon } from 'assets/icons/hospital.svg';
import { ReactComponent as HouseIcon } from 'assets/icons/house.svg';
import { ReactComponent as IdCardIcon } from 'assets/icons/idCard.svg';
import { ReactComponent as IdCardBadge } from 'assets/icons/idCardBadge.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as InsightDownloadForm } from 'assets/icons/insightDownloadForm.svg';
import { ReactComponent as Insights } from 'assets/icons/insights.svg';
import { ReactComponent as InsightsCaptureNextGenModule } from 'assets/icons/insights-nextgen-module.svg';
import { ReactComponent as InsightsAi } from 'assets/icons/insightsAi.svg';
import { ReactComponent as InsightsCaptureModule } from 'assets/icons/insightsCaptureModule.svg';
import { ReactComponent as InsightsGroup } from 'assets/icons/insightsGroup.svg';
import { ReactComponent as InsightsModule } from 'assets/icons/InsightsModule.svg';
import { ReactComponent as InsightsSparkle } from 'assets/icons/insightsSparkle.svg';
import { ReactComponent as Interactions } from 'assets/icons/interactions.svg';
import { ReactComponent as IsProfiled } from 'assets/icons/isProfiled.svg';
import { ReactComponent as Journals } from 'assets/icons/journals.svg';
import { ReactComponent as Kol } from 'assets/icons/kol.svg';
import { ReactComponent as KolGroup } from 'assets/icons/kolGroup.svg';
import { ReactComponent as KwelloLogoMenu } from 'assets/icons/kwelloLogoMenu.svg';
import { ReactComponent as KwelloShortLogo } from 'assets/icons/kwelloShortLogo.svg';
import { ReactComponent as Laptop } from 'assets/icons/laptop.svg';
import { ReactComponent as Leadership } from 'assets/icons/leadership.svg';
import { ReactComponent as Lightbulb } from 'assets/icons/lightbulb.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { ReactComponent as ListAdd } from 'assets/icons/listAdd.svg';
import { ReactComponent as BulletListIcon } from 'assets/icons/listBullets.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as MagnifyingGlass } from 'assets/icons/magnifyingStar.svg';
import { ReactComponent as MegaphoneIcon } from 'assets/icons/megaphone.svg';
import { ReactComponent as Menu } from 'assets/icons/menu.svg';
import { ReactComponent as MslIcon } from 'assets/icons/msl.svg';
import { ReactComponent as PaperClip } from 'assets/icons/paperClip.svg';
import { ReactComponent as Pdf } from 'assets/icons/pdf.svg';
import { ReactComponent as Pencil } from 'assets/icons/pencil.svg';
import { ReactComponent as PencilWrite } from 'assets/icons/pencilWrite.svg';
import { ReactComponent as PharmaSpectraIcon } from 'assets/icons/pharmaSpectra.svg';
import { ReactComponent as PieChartIcon } from 'assets/icons/pieChart.svg';
import { ReactComponent as PlusCircle } from 'assets/icons/plusCircle.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { ReactComponent as ProfileLinkIcon } from 'assets/icons/profileLinkIcon.svg';
import { ReactComponent as ProfileRemove } from 'assets/icons/profileRemove.svg';
import { ReactComponent as ProfileSummary } from 'assets/icons/profileSummary.svg';
import { ReactComponent as QuestionComparison } from 'assets/icons/questionComparison.svg';
import { ReactComponent as QuestionFocus } from 'assets/icons/questionFocus.svg';
import { ReactComponent as HelpMessageIcon } from 'assets/icons/questionHelpMessage.svg';
import { ReactComponent as RadioButton } from 'assets/icons/radioButton.svg';
import { ReactComponent as RadioButtonSelected } from 'assets/icons/radioButtonSelected.svg';
import { ReactComponent as ReleaseNotes } from 'assets/icons/releaseNotes.svg';
import { ReactComponent as RemoveButton } from 'assets/icons/removeButton.svg';
import { ReactComponent as RightArrow } from 'assets/icons/rightArrow.svg';
import { ReactComponent as RightChevron } from 'assets/icons/rightChevron.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { ReactComponent as ScienceRankingBadgeIcon } from 'assets/icons/scienceRankingBadge.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as SmallSearch } from 'assets/icons/searchIcon.svg'; // new design search icon
import { ReactComponent as NegativeIcon } from 'assets/icons/sentiment/negative.svg';
import { ReactComponent as NeutralIcon } from 'assets/icons/sentiment/neutral.svg';
import { ReactComponent as PositiveIcon } from 'assets/icons/sentiment/positive.svg';
import { ReactComponent as ShortLeftArrow } from 'assets/icons/shortLeftArrow.svg';
import { ReactComponent as ShortRightArrow } from 'assets/icons/shortRightArrow.svg';
import { ReactComponent as SmallCheck } from 'assets/icons/smallCheck.svg';
import { ReactComponent as SmoothPencil } from 'assets/icons/smoothPencil.svg';
import { ReactComponent as SocialMonitoringModule } from 'assets/icons/socialMonitoringModule.svg';
import { ReactComponent as SocialRankingBadgeIcon } from 'assets/icons/socialRankingBadge.svg';
import { ReactComponent as RedWarningTriangle } from 'assets/icons/solidRedWarningTriangle.svg';
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import { ReactComponent as SOSVExpertsModule } from 'assets/icons/SOSVExpertsModule.svg';
import { ReactComponent as SOSVScienceModule } from 'assets/icons/SOSVScienceModule.svg';
import { ReactComponent as BlogIcon } from 'assets/icons/source/blog.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/source/facebook.svg';
import { ReactComponent as ForumIcon } from 'assets/icons/source/forum.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/source/instagram.svg';
import { ReactComponent as LinkedInIcon } from 'assets/icons/source/linkedIn.svg';
import { ReactComponent as NewsIcon } from 'assets/icons/source/news.svg';
import { ReactComponent as QQIcon } from 'assets/icons/source/QQ.svg';
import { ReactComponent as RedditIcon } from 'assets/icons/source/reddit.svg';
import { ReactComponent as RssIcon } from 'assets/icons/source/rss.svg';
import { ReactComponent as ScienceIcon } from 'assets/icons/source/science.svg';
import { ReactComponent as TikTokIcon } from 'assets/icons/source/tiktok.svg';
import { ReactComponent as TumblrIcon } from 'assets/icons/source/tumblr.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/source/twitter.svg';
import { ReactComponent as WebIcon } from 'assets/icons/source/web.svg';
import { ReactComponent as YouTubeIcon } from 'assets/icons/source/youtube.svg';
import { ReactComponent as SpeachBubble } from 'assets/icons/speachBubble.svg';
import { ReactComponent as Specialties } from 'assets/icons/specialties.svg';
import { ReactComponent as Splitter } from 'assets/icons/splitter.svg';
import { ReactComponent as SubmitArrow } from 'assets/icons/submitArrow.svg';
import { ReactComponent as Summary } from 'assets/icons/summary.svg';
import { ReactComponent as SwitchOff } from 'assets/icons/switchOff.svg';
import { ReactComponent as SwitchOn } from 'assets/icons/switchOn.svg';
import { ReactComponent as TeamChat } from 'assets/icons/teamChat.svg';
import { ReactComponent as ThumbsDown } from 'assets/icons/thumbsDown.svg';
import { ReactComponent as ThumbsUp } from 'assets/icons/thumbsUp.svg';
import { ReactComponent as Tick } from 'assets/icons/tick.svg';
import { ReactComponent as TickedCheckBox } from 'assets/icons/tickedCheckBox.svg';
import { ReactComponent as TickSelector } from 'assets/icons/tickSelector.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { ReactComponent as WarningTriangleRedFill } from 'assets/icons/triangleWarning.svg';
import { ReactComponent as UpChevron } from 'assets/icons/upChevron.svg';
import { ReactComponent as UserActionsIcon } from 'assets/icons/userActions.svg';
import { ReactComponent as WarningTriangleRed } from 'assets/icons/warningTriangleRed.svg';
import { ReactComponent as YellowQuestionMark } from 'assets/icons/yellowQuestionMark.svg';
import PropTypes from 'prop-types';

import styles from './Icon.module.scss';

export const availableIcons = {
  alertOn: <AlertOn />,
  alertOff: <AlertOff />,
  goBack: <GoBack />,
  search: <Search />,
  check: <CheckIcon />,
  calendar: <Calendar />,
  cross: <Cross />,
  crossCircle: <CrossCircle />,
  trash: <Trash />,
  cog: <Cog />,
  specialties: <Specialties />,
  isProfiled: <IsProfiled />,
  radioButton: <RadioButton />,
  radioButtonSelected: <RadioButtonSelected />,
  listAdd: <ListAdd />,
  pencil: <Pencil />,
  connections: <Connections />,
  kol: <Kol />,
  arrowDown: <ArrowDown />,
  arrowUp: <ArrowUp />,
  analytics: <AnalyticsIcon />,
  bookmarkOff: <BookmarkOff />,
  bookmarkOn: <BookmarkOn />,
  bulletList: <BulletListIcon />,
  helpMessage: <HelpMessageIcon />,
  releaseNotes: <ReleaseNotes />,
  house: <HouseIcon />,
  pieChart: <PieChartIcon />,
  userActions: <UserActionsIcon />,
  funnel: <FunnelIcon />,
  arrowStraight: <ArrowStraight />,
  doubleArrowLeft: <DoubleArrowLeft />,
  arrowLeft: <ArrowLeft />,
  arrowRight: <ArrowRight />,
  doubleArrowRight: <DoubleArrowRight />,
  fullscreen: <Fullscreen />,
  menu: <Menu />,
  globe: <GlobeIcon />,
  info: <InfoIcon />,
  profile: <ProfileIcon />,
  speachBubble: <SpeachBubble />,
  eraser: <Eraser />,
  events: <Events />,
  calendarWarning: <CalendarWarning />,
  downloadArrow: <DownloadArrow />,
  twitter: <TwitterIcon />,
  hospital: <HospitalIcon />,
  profileLink: <ProfileLinkIcon />,
  linkedIn: <LinkedInIcon />,
  idCard: <IdCardIcon />,
  scienceRanking: <ScienceRankingBadgeIcon />,
  socialRanking: <SocialRankingBadgeIcon />,
  youtube: <YouTubeIcon />,
  pharmaSpectra: <PharmaSpectraIcon />,
  socialMonitoringModule: <SocialMonitoringModule />,
  sosvScienceModule: <SOSVScienceModule />,
  sosvExpertsModule: <SOSVExpertsModule />,
  insightsModule: <InsightsModule />,
  insightsNextGenModule: <InsightsCaptureNextGenModule />,
  globalKOLPlanningModule: <GlobalKOLPlanningModule />,
  customReportsModule: <CustomReportsModule />,
  pdf: <Pdf />,
  handPointer: <HandPointer />,
  feed: <FeedIcon />,
  pencilWrite: <PencilWrite />,
  idCardBadge: <IdCardBadge />,
  floppyDisk: <FloppyDisk />,
  headphones: <Headphones />,
  teamChat: <TeamChat />,
  alignment: <Alignment />,
  detail: <Detail />,
  insights: <Insights />,
  interactions: <Interactions />,
  leadership: <Leadership />,
  questionComparison: <QuestionComparison />,
  questionFocus: <QuestionFocus />,
  summary: <Summary />,
  profileSummary: <ProfileSummary />,
  insightDownloadForm: <InsightDownloadForm />,
  sOSV: <HouseIcon />,
  conferences: <Conferences />,
  geography: <Geography />,
  journals: <Journals />,
  profileSelection: <Kol />,
  linkIcon: <LinkIcon />,
  calendarInfo: <CalendarInfo />,
  laptop: <Laptop />,
  kolGroup: <KolGroup />,
  switchOn: <SwitchOn />,
  switchOff: <SwitchOff />,
  sortIcon: <SortIcon />,
  exportToCsvIcon: <ExportToCsvIcon />,
  greenCheck: <CheckIconGreen />,
  yellowCheck: <CheckIconYellow />,
  redWarningTriangle: <WarningTriangleRed />,
  redWarningTriangleFill: <WarningTriangleRedFill />,
  removeProfile: <ProfileRemove />,
  addInteraction: <AddInteraction />,
  addProfile: <AddProfile />,
  addToCollection: <AddToCollection />,
  saveDisc: <SaveIcon />,
  megaphone: <MegaphoneIcon />,
  graphStatsAscend: <GraphStatsAscendIcon />,
  sadFace: <NegativeIcon />,
  neutralFace: <NeutralIcon />,
  happyFace: <PositiveIcon />,
  blog: <BlogIcon />,
  faceBook: <FacebookIcon />,
  forum: <ForumIcon />,
  instagram: <InstagramIcon />,
  news: <NewsIcon />,
  qq: <QQIcon />,
  reddit: <RedditIcon />,
  rss: <RssIcon />,
  science: <ScienceIcon />,
  tumblr: <TumblrIcon />,
  tiktok: <TikTokIcon />,
  web: <WebIcon />,
  paperClip: <PaperClip />,
  envelope: <Envelope />,
  magnifyingGlass: <MagnifyingGlass />,
  tickBox: <TickBox />,
  createCollection: <CreateCollection />,
  freeForm: <Freeform />,
  rightChevron: <RightChevron />,
  downChevron: <DownChevron />,
  upChevron: <UpChevron />,
  checkBadge: <CheckBadge />,
  emptyCheckBox: <EmptyCheckBox />,
  tickedCheckBox: <TickedCheckBox />,
  plusCircle: <PlusCircle />,
  smoothPencil: <SmoothPencil />,
  logout: <Logout />,
  shortRightArrow: <ShortRightArrow />,
  shortLeftArrow: <ShortLeftArrow />,
  smallSearch: <SmallSearch />,
  clearLeft: <ClearLeft />,
  tick: <Tick />,
  rightArrow: <RightArrow />,
  insightsAi: <InsightsAi />,
  paperWithClip: <PaperWithClip />,
  clockCircle: <ClockCircle />,
  copy: <Copy />,
  insightsSparkle: <InsightsSparkle />,
  submitArrow: <SubmitArrow />,
  msl: <MslIcon />,
  tickSelector: <TickSelector />,
  emptySelector: <EmptySelector />,
  smallCheck: <SmallCheck />,
  hamburger: <Hamburger />,
  removeButton: <RemoveButton />,
  groupCircle: <GroupCircle />,
  commentGroupCircle: <CommentGroup />,
  insightsGroupCircle: <InsightsGroup />,
  exclamation: <Exclamation />,
  insightsCaptureModule: <InsightsCaptureModule />,
  kwelloShortLogo: <KwelloShortLogo />,
  kwelloLogoMenu: <KwelloLogoMenu />,
  elsieAI: <Lightbulb />,
  splitter: <Splitter />,
  yellowQuestionMark: <YellowQuestionMark />,
  checkGreenCircle: <CheckGreenCircle />,
  solidRedWarningTriangle: <RedWarningTriangle />,
  thumbsUp: <ThumbsUp />,
  thumbsDown: <ThumbsDown />,
};

const Icon = ({ className, name, size }) =>
  availableIcons[name]
    ? cloneElement(availableIcons[name], {
        className: `${styles.icon} ${className}`,
        style: { height: size, width: size },
      })
    : '';

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Icon.defaultProps = {
  className: '',
  size: undefined,
};

export default Icon;
