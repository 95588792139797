import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AuthProvider } from 'context/authContext';
import { ConfirmationProvider } from 'context/confirmationContext';
import { ExternalLinkProvider } from 'context/externalLinkContext';
import { FeatureProvider } from 'context/featureContext';
import { LayoutProvider } from 'context/layoutContext';
import { ModalProvider } from 'context/modalContext';
import { SlideDrawerProvider } from 'context/slideDrawerContext';
import { StatusProvider } from 'context/statusContext';
import { UserProvider } from 'context/userContext';

import { reactPlugin } from 'utils/appInsights/appInsights';

import 'react-image-crop/dist/ReactCrop.css';
import 'rc-slider/assets/index.css';
import './index.scss';
import 'react-toggle/style.css';
import 'react-datepicker/dist/react-datepicker.css';

import { msalConfig } from './authConfig';
import reportWebVitals from './reportWebVitals';
import { Root } from './Routes/Root';

const msalInstance = new PublicClientApplication(msalConfig);

const queryClient = new QueryClient();
const root = createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <Router>
      <QueryClientProvider client={queryClient}>
        <StatusProvider>
          <StrictMode>
            <AppInsightsContext.Provider value={reactPlugin}>
              <MsalProvider instance={msalInstance}>
                <Auth0Provider
                  domain={process.env.REACT_APP_AUTH0_DOMAIN}
                  clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
                  authorizationParams={{
                    redirect_uri: `${process.env.REACT_APP_DEFAULT_AZURE_AUTH_REDIRECT_URI}`,
                    audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`,
                  }}
                  useRefreshTokens
                >
                  <ModalProvider>
                    <AuthProvider>
                      <FeatureProvider>
                        <UserProvider>
                          <LayoutProvider>
                            <SlideDrawerProvider>
                              <ConfirmationProvider>
                                <ExternalLinkProvider>
                                  <Route path="/" component={Root} />
                                </ExternalLinkProvider>
                              </ConfirmationProvider>
                            </SlideDrawerProvider>
                          </LayoutProvider>
                        </UserProvider>
                      </FeatureProvider>
                    </AuthProvider>
                  </ModalProvider>
                </Auth0Provider>
              </MsalProvider>
            </AppInsightsContext.Provider>
          </StrictMode>
        </StatusProvider>
      </QueryClientProvider>
    </Router>
  </HelmetProvider>,
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
