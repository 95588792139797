const moduleName = {
  socialMonitoring: 'Social Monitoring',
  globalKolPlanning: 'Global KOL Planning',
  insights: 'Insights',
  'sosv-experts': 'SOSV Experts',
  'sosv-science': 'SOSV Science',
  'custom-reports': 'Custom Content',
  'insights-capture': 'Insights Capture',
};

const productName = 'Kwello';

/**
 * @function pageTitle
 * @param {string} pageName - The name of the page
 * @param {string} module - The name of the module
 * @returns {string} - The page title
 */
const pageTitle = (pageName, module) => {
  if (!pageName) {
    return productName;
  }
  if (module) {
    // Temporary page title until page routing for these modules is implemented
    if (module !== 'socialMonitoring') {
      return `${moduleName[module]} | ${pageName} | ${productName}`;
    }
    return `${pageName} | ${moduleName[module]} | ${productName}`;
  }
  return `${pageName} | ${productName} |`;
};

export default pageTitle;
